var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLBASEINFO" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && _vm.tbmData.tbmId
                            ? _c("c-qr-btn", {
                                attrs: {
                                  mobileUrl:
                                    "/sai/tbm/tbm?tbmId=" + _vm.tbmData.tbmId,
                                },
                              })
                            : _vm._e(),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.tabParam.tbmId,
                                expression: "tabParam.tbmId",
                              },
                            ],
                            attrs: { label: "출력", icon: "print" },
                            on: { btnClicked: _vm.printTbm },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable && !_vm.tabParam.tbmId,
                                expression: "editable && !tabParam.tbmId",
                              },
                            ],
                            attrs: {
                              label: "타 TBM 불러오기",
                              icon: "save_alt",
                            },
                            on: { btnClicked: _vm.copyTbm },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  _vm.tabParam.tbmId &&
                                  !_vm.disabled &&
                                  !_vm.isApprDisabled,
                                expression:
                                  "editable && tabParam.tbmId && !disabled && !isApprDisabled",
                              },
                            ],
                            attrs: {
                              label: "LBLREMOVE",
                              editable: _vm.editable,
                              icon: "delete_forever",
                            },
                            on: { btnClicked: _vm.remove },
                          }),
                          !_vm.isApprDisabled
                            ? _c("c-appr-btn", {
                                ref: "appr-btn",
                                attrs: {
                                  name: "danger-journal-appr-btn",
                                  editable: _vm.editable,
                                  approvalInfo: _vm.approvalInfo,
                                },
                                on: {
                                  beforeApprAction: _vm.saveDataAppr,
                                  callbackApprAction: _vm.approvalCallback,
                                  requestAfterAction: _vm.getDetail,
                                },
                              })
                            : _vm._e(),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable && !_vm.disabled,
                                expression: "editable && !disabled",
                              },
                            ],
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.tabParam,
                              mappingType: _vm.saveType,
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveTbm,
                              btnCallback: _vm.saveCallback,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: true,
                            label: "TBM번호",
                            name: "tbmNo",
                          },
                          model: {
                            value: _vm.tabParam.tbmNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "tbmNo", $$v)
                            },
                            expression: "tabParam.tbmNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "TBM명",
                            name: "tbmName",
                          },
                          model: {
                            value: _vm.tabParam.tbmName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "tbmName", $$v)
                            },
                            expression: "tabParam.tbmName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            afterIcon:
                              _vm.editable && !_vm.disabled
                                ? [
                                    {
                                      name: "search",
                                      click: true,
                                      callbackName: "searchWorkPermit",
                                      color: "teal",
                                    },
                                    {
                                      name: "close",
                                      click: true,
                                      callbackName: "removeWorkPermit",
                                      color: "red",
                                    },
                                  ]
                                : null,
                            editable: _vm.editable,
                            disabled: true,
                            label: "작업허가번호",
                            name: "permitNo",
                          },
                          on: {
                            searchWorkPermit: _vm.searchWorkPermit,
                            removeWorkPermit: _vm.removeWorkPermit,
                          },
                          model: {
                            value: _vm.tabParam.permitNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "permitNo", $$v)
                            },
                            expression: "tabParam.permitNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            type: "edit",
                            name: "plantCd",
                          },
                          on: { datachange: _vm.datachangePlant },
                          model: {
                            value: _vm.tabParam.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "plantCd", $$v)
                            },
                            expression: "tabParam.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-process", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "LBLPROCESS",
                            name: "processCd",
                          },
                          on: { datachange: _vm.datachangeProcess },
                          model: {
                            value: _vm.tabParam.processCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "processCd", $$v)
                            },
                            expression: "tabParam.processCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "LBLJOB",
                            name: "sopName",
                          },
                          model: {
                            value: _vm.tabParam.sopName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "sopName", $$v)
                            },
                            expression: "tabParam.sopName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-vendor", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            haveProcess: true,
                            label: "업체",
                            name: "vendorCd",
                          },
                          model: {
                            value: _vm.tabParam.vendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "vendorCd", $$v)
                            },
                            expression: "tabParam.vendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업일",
                            type: "date",
                            name: "tbmWorkDate",
                          },
                          model: {
                            value: _vm.tabParam.tbmWorkDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "tbmWorkDate", $$v)
                            },
                            expression: "tabParam.tbmWorkDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업책임자",
                            name: "tbmWorkResponsibleId",
                          },
                          model: {
                            value: _vm.tabParam.tbmWorkResponsibleId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tabParam,
                                "tbmWorkResponsibleId",
                                $$v
                              )
                            },
                            expression: "tabParam.tbmWorkResponsibleId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-construnction", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            name: "sopConstructionId",
                          },
                          model: {
                            value: _vm.tabParam.sopConstructionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "sopConstructionId", $$v)
                            },
                            expression: "tabParam.sopConstructionId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            isArray: false,
                            codeGroupCd: "TBM_PRE_INSPECTION_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "사전점검",
                            name: "preInspections",
                          },
                          model: {
                            value: _vm.tabParam.preInspections,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "preInspections", $$v)
                            },
                            expression: "tabParam.preInspections",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업내용",
                            rows: 2,
                            name: "tbmWorkContents",
                          },
                          model: {
                            value: _vm.tabParam.tbmWorkContents,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "tbmWorkContents", $$v)
                            },
                            expression: "tabParam.tbmWorkContents",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "특이사항",
                            rows: 2,
                            name: "significant",
                          },
                          model: {
                            value: _vm.tabParam.significant,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "significant", $$v)
                            },
                            expression: "tabParam.significant",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-table",
                {
                  ref: "riskTable",
                  attrs: {
                    title: "위험요인 목록",
                    columns: _vm.grid.columns,
                    gridHeight: _vm.grid.height,
                    data: _vm.tabParam.tbmRiskHazardModelList,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    noDataLabel: "위험요인을 추가하세요.",
                    hideBottom: true,
                    editable: _vm.editable && !_vm.disabled,
                    rowKey: "tbmRiskId",
                    selection: "multiple",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customArea",
                      fn: function ({ props, col }) {
                        return [
                          col.name === "tbmJobStep"
                            ? [
                                props.row["tbmPermitFlag"] === "N"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.datachange(props)
                                        },
                                      },
                                      model: {
                                        value: props.row["tbmJobStep"],
                                        callback: function ($$v) {
                                          _vm.$set(props.row, "tbmJobStep", $$v)
                                        },
                                        expression: "props.row['tbmJobStep']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.row["tbmJobStep"]) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                          col.name === "tbmRiskHazard"
                            ? [
                                props.row["tbmPermitFlag"] === "N"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.datachange(props)
                                        },
                                      },
                                      model: {
                                        value: props.row["tbmRiskHazard"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.row,
                                            "tbmRiskHazard",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "props.row['tbmRiskHazard']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.row["tbmRiskHazard"]) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                          col.name === "tbmImprovementMeasures"
                            ? [
                                props.row["tbmPermitFlag"] === "N"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.datachange(props)
                                        },
                                      },
                                      model: {
                                        value:
                                          props.row["tbmImprovementMeasures"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.row,
                                            "tbmImprovementMeasures",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "props.row['tbmImprovementMeasures']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.row["tbmImprovementMeasures"]
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "4M에서 선택",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addFmRisk },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "LBLADDDIRECTLY",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addRisk },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.tabParam.tbmRiskHazardModelList.length > 0
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "LBLEXCEPT",
                                  icon: "remove",
                                },
                                on: { btnClicked: _vm.removeRisk },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("c-upload", {
                attrs: {
                  attachInfo: _vm.attachInfo,
                  editable: _vm.editable && !_vm.disabled,
                  label: "교육자료",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }